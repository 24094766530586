import { breakpoints, g } from '@pretto/bricks/components/layout'
import { btoa } from '@pretto/bricks/core/utility/btoa'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import styled from 'styled-components'

export const Banner = styled.div`
  background-image: url('data:image/svg+xml;base64,${({ theme }) =>
      btoa(
        `<svg viewBox="0 0 203.94 181" xmlns="http://www.w3.org/2000/svg"><path d="M203.94,0H119.2C111.34,34.6,41.5,104.09,0,181H203.94Z" fill="${theme.legacy.colors.neutral1.light}" /></svg>`
      )}'),
    url('data:image/svg+xml;base64,${({ theme }) =>
      btoa(
        `<svg viewBox="0 0 242.94 93.9" xmlns="http://www.w3.org/2000/svg"><path d="M0,93.9C58.37,77.63,152.88,30.38,242.94,0H0Z" fill="${theme.legacy.colors.neutral1.light}" /></svg>`
      )}');
  background-position: right center, left top;
  background-repeat: no-repeat;
  background-size: auto 100%, auto 55%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-image: url('${getCloudinaryUrl('v1686062888/zen/static-assets/phone-truncated.png', {
        height: 292,
      })}'),
      url('data:image/svg+xml;base64,${({ theme }) =>
        btoa(
          `<svg viewBox="0 0 203.94 181" xmlns="http://www.w3.org/2000/svg"><path d="M203.94,0H119.2C111.34,34.6,41.5,104.09,0,181H203.94Z" fill="${theme.legacy.colors.neutral1.light}" /></svg>`
        )}'),
      url('data:image/svg+xml;base64,${({ theme }) =>
        btoa(
          `<svg viewBox="0 0 242.94 93.9" xmlns="http://www.w3.org/2000/svg"><path d="M0,93.9C58.37,77.63,152.88,30.38,242.94,0H0Z" fill="${theme.legacy.colors.neutral1.light}" /></svg>`
        )}');
    background-position: right ${g(4)} bottom, right center, left top;
    background-repeat: no-repeat;
    background-size: auto calc(100% - ${g(4)}), auto 100%, auto 55%;
    padding-right: ${100 / 3}%;
  }
`

export const Description = styled.div`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
  }
`

export const StoreBadge = styled.div`
  height: ${g(5)};

  & + & {
    margin-left: ${g(2)};
  }

  img {
    display: block;
    height: 100%;
  }
`

export const StoreBadges = styled.div`
  display: flex;
`

export const Title = styled.div`
  margin-bottom: ${g(1)};
`
