import StoreButton from '@pretto/bricks/components/buttons/StoreButton'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import { prettoSearch } from '@pretto/bricks/core/config'

import * as S from './styles'

const PrettoSearchBanner = () => (
  <Card format="line" variant="neutral-1-20">
    <S.Banner>
      <Content>
        <S.Title>
          <SubHeading size="large">Pretto Search</SubHeading>
        </S.Title>

        <S.Description>
          <Text variant="neutral-1-60">
            {`L'App qui vous alerte en premier pour ne pas laisser filer le bien de vos rêves, partout en France.`}
          </Text>
        </S.Description>

        <S.StoreBadges>
          {Object.values(prettoSearch).map(({ slug }) => (
            <S.StoreBadge key={slug}>
              <StoreButton slug={slug} />
            </S.StoreBadge>
          ))}
        </S.StoreBadges>
      </Content>
    </S.Banner>
  </Card>
)

export default PrettoSearchBanner
