import PrettoSearchBannerComponent from '@pretto/bricks/shared/components/PrettoSearchBanner'

import * as S from '../styles'

const PrettoSearchBanner = () => (
  <S.BlockLarge>
    <PrettoSearchBannerComponent />
  </S.BlockLarge>
)

export default PrettoSearchBanner
